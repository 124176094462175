<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-card-title>
      {{ $t('components.website.chat.addTooltip') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
        <v-select
          v-model="type"
          :label="$t('components.website.chat.typeLabel')"
          :items="types"
          :rules="[ getRequiredRule() ]"
        />
        <v-text-field
          v-model="title"
          :label="$t('components.website.chat.titleLabel')"
          :rules="[ getRequiredRule() ]"
        />
        <v-textarea
          v-model="message"
          :label="$t('components.website.chat.messageLabel')"
          :rules="[ getRequiredRule() ]"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="green" :loading="loading" @click="onSubmitChat">
        {{ $t('components.website.chat.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import ChatTypes from './types'

  export default {
    name: 'ChatStartForm',
    components: { ValidationsAlert },
    mixins: [
      FormValidations(),
      Themeable,
    ],
    data: vm => ({
      valid: false,
      type: null,
      title: null,
      message: null,
      loading: false,
    }),
    computed: {
      types () {
        return ChatTypes(this)
      },
    },
    methods: {
      resetForm () {
        this.message = null
        this.type = null
        this.title = null
      },
      onSubmitChat () {
        this.$refs.form.validate()

        if (this.valid) {
          this.loading = true
          this.$store.dispatch('chat/submitSupportIssue', {
            message: this.message,
            data: {
              type: this.type,
              title: this.title,
            },
          })
            .then(json => {
              this.updateFormSuccessMessage(json.message)
              this.resetForm()
              this.$refs.form.resetValidation()
              this.$emit('success', json)
            })
            .catch(error => {
              this.updateFormValidationErrors(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
    },
  }
</script>
