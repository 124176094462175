<template>
  <v-list-item @click="onShowDetails">
    <chat-details-dialog v-model="details" :room="value" />
    <v-list-item-content>
      <v-list-item-title>
        <div class="d-flex flex-row justify-space-between">
          <span>{{ roomTitle }}</span>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-chip label small>{{ roomTypeString }}</v-chip>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip label small>{{ roomTimestamp }}</v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip top>
        <template v-slot:activator="{on}">
          <v-badge :value="roomUnseenMessagesCount > 0" :content="roomUnseenMessagesCountString" color="warning" left>
            <v-btn icon color="primary" v-on="on" @click="onShowDetails"><v-icon>mdi-chat-processing</v-icon></v-btn>
          </v-badge>
        </template>
        {{ $t('components.website.chat.details') }}
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import ChatRoom from '@peynman/press-vue-core/mixins/ChatRoom'
  import ChatDetailsDialog from './ChatDetailsDialog.vue'

  export default {
    name: 'ChatRoomItem',
    components: {
      ChatDetailsDialog,
    },
    mixins: [ChatRoom],
    props: {
      value: Object,
    },
    data: vm => ({
      details: false,
    }),
    methods: {
      onShowDetails () {
        this.details = true
      },
    },
  }
</script>
