<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('components.website.chat.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn-toggle v-model="chatTypes" mandatory>
        <v-btn small>{{ $t('components.website.chat.open') }}</v-btn>
        <v-btn small>{{ $t('components.website.chat.closed') }}</v-btn>
      </v-btn-toggle>
      <v-dialog max-width="766px">
        <template v-slot:activator="dl">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn icon v-on="{ ...on, ...dl.on }">
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
            </template>
            {{ $t('components.website.chat.addTooltip') }}
          </v-tooltip>
        </template>
        <chat-start-form />
      </v-dialog>
    </v-toolbar>
    <v-card-text>
      <chat-rooms-list :type="chatTypes === 0 ? 'open' : 'closed'" />
    </v-card-text>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ChatRoomsList from './ChatRoomsList.vue'
  import ChatStartForm from './ChatStartForm.vue'

  export default {
    name: 'ChatCard',
    components: {
      ChatStartForm,
      ChatRoomsList,
    },
    mixins: [Themeable],
    data: vm => ({
      chatTypes: 0,
    }),
  }
</script>
