<template>
  <v-list>
    <div v-if="rooms.length > 0">
      <div v-for="room in rooms" :key="`chat-room-${room.id}`">
        <chat-room-item :value="room" />
        <v-divider />
      </div>
    </div>
    <v-list-item v-else>
      <v-list-item-content>
        <v-progress-circular v-if="loading" indeterminate />
        <v-list-item-title v-else>
          <span>{{ $t('components.website.chat.empty') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="total > rooms.length">
      <v-list-item-content>
        <v-btn text :loading="loading" @click="loadNextPage">
          {{ $t('components.website.chat.loadMore') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import ChatRoomItem from './ChatRoomItem.vue'

  export default {
    name: 'ChatRoomsList',
    components: {
      ChatRoomItem,
    },
    props: {
      type: String,
    },
    data: vm => ({
      rooms: [],
      loading: false,
      total: 0,
      page: -1, // load next page adds 1
    }),
    watch: {
      type () {
        this.page = -1
        this.rooms = []
        this.total = 0
        this.loadNextPage()
      },
    },
    mounted () {
      this.loadNextPage()
    },
    methods: {
      loadNextPage () {
        this.loading = true
        this.page += 1
        this.$store.dispatch('chat/fetchChatRooms', {
          page: this.page,
          closed: this.type === 'closed',
        })
          .then(json => {
            this.page = json.currPage
            this.total = json.total
            this.rooms.push(...json.items)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
