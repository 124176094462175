<template>
  <website>
    <chat-card />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import ChatCard from '../components/Chat/ChatCard.vue'

  export default {
    name: 'ModenaChats',
    components: {
      Website,
      ChatCard,
    },
  }
</script>
