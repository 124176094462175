<template>
  <v-dialog v-model="internalValue" max-width="766px" fullscreen @input="$emit('input', internalValue)">
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on" />
    </template>
    <chat-room-details
      :dark="theme.website.chat.card.dark"
      :light="theme.website.chat.card.light"
      :color="theme.website.chat.card.color"
      :bubble-dark="theme.website.chat.bubble.dark"
      :bubble-light="theme.website.chat.bubble.light"
      :bubble-color="theme.website.chat.bubble.color"
      :value="room"
      show-close
      @close="closeDialog"
    />
  </v-dialog>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ChatRoomDetails from '@peynman/press-vue-admin/components/Inputs/Chat/ChatRoomDetails.vue'

  export default {
    name: 'ChatDetailsDialog',
    components: {
      ChatRoomDetails,
    },
    mixins: [
      Themeable,
    ],
    props: {
      value: Boolean,
      room: Object,
    },
    data: vm => ({
      dialog: false,
      internalValue: vm.value,
    }),
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      closeDialog () {
        this.internalValue = false
        this.$emit('input', this.internalValue)
      },
    },
  }
</script>
